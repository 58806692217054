import "./App.css";
import Editable from "./Editable";
import "./Normalize.css";
import { useAppStateDispatch } from "./contexts/AppContext";
import { useFirebaseContext } from "./contexts/firebaseContext";
import { setInitialUserValues } from "./helpers";
import type { Question, User } from "./types";
import Home from "./views/home";
import Profile from "./views/profile";
import type { User as GoogleUser } from "firebase/auth";
import { getAuth } from "firebase/auth";
import {
  collection,
  getDocs,
  query,
  doc,
  getDoc,
  setDoc,
  onSnapshot,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { v4 as uuid } from "uuid";

const auth = getAuth();

const router = createBrowserRouter([
  {
    path: "/:profileId",
    element: <Profile />,
  },
  {
    path: "*",
    element: <Home />,
  },
]);

function App() {
  const { db } = useFirebaseContext();
  const dispatch = useAppStateDispatch();
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    const handleGoogleUser = async ({
      uid,
      displayName,
      email,
    }: GoogleUser) => {
      let loggedInUser;

      const userRef = doc(db, "users-lite", uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        loggedInUser = await userSnap.data();
      } else {
        const createdUser = setInitialUserValues({
          name: displayName || `mesuc-er-${uuid()}`,
          id: uid,
          email: email || "",
        });
        await setDoc(doc(db, "users", uid), createdUser);
        await setDoc(doc(db, "users-lite", uid), {
          id: createdUser.id,
          email: createdUser.email,
          name: createdUser.name,
          photo: createdUser.photo,
        });
        loggedInUser = createdUser;
      }
      dispatch({
        type: "login",
        id: loggedInUser.id,
        name: loggedInUser.name,
      });
      setIsAuthenticating(false);
    };

    const unsubscribe = auth.onAuthStateChanged((googleUser) => {
      if (!googleUser) {
        dispatch({ type: "logout" });
        setIsAuthenticating(false);
      } else {
        handleGoogleUser(googleUser);
      }
    });

    return () => {
      unsubscribe && unsubscribe();
    };
  }, []);

  useEffect(() => {
    // fetch all the users ans listen for updates
    const q = query(collection(db, "users-lite"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let users: Record<string, User> = {};
      querySnapshot.forEach((doc) => {
        // @ts-ignore
        users[doc.data().id] = doc.data();
      });
      dispatch({
        type: "setUsers",
        users,
      });
    });

    return () => {
      unsubscribe && unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // fetch all the questions
    (async () => {
      let questions: Record<string, Question> = {};
      const q = query(collection(db, "questions"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        if (data) {
          questions[data.id.trim()] = data;
        }
      });
      dispatch({
        type: "setQuestions",
        questions,
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAuthenticating) {
    return <></>;
  }

  return (
    <>
      <Editable />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
