import { User } from "./types";

export const setInitialUserValues = (user: {
    id: string;
    email: string;
    name: string;
}): User => {
    return {
        bio: "click here to edit your short bio!",
        general: "click here to enter your longer bio!",
        quote: "click here to edit quote!",
        location: "click here to enter your location!",
        movie: "Click here to add your favorite movies!",
        music: "Click here to add your favorite music!",
        tv: "Click here to add your favorite tv!",
        youtube: "https://www.youtube.com/watch?v=WAsezaBmuJ8",
        photo: "https://firebasestorage.googleapis.com/v0/b/mesuc-space.appspot.com/o/%20bufo.png?alt=media&token=c758171a-f9ca-4238-92a5-8a58e507c81e",
        ...user,
    };
};