import { useAppStateContext } from "../../contexts/AppContext";
import { useFirebaseContext } from "../../contexts/firebaseContext";
import { Comment } from "../../types";
import {
  arrayRemove,
  arrayUnion,
  doc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

function Comments({
  comments,
  answerId,
  updateComment,
}: {
  comments: Array<Comment>;
  answerId: string;
  updateComment: (answerId: string, comment: Comment, remove?: boolean) => void;
}) {
  const { db } = useFirebaseContext();
  const { users, loggedInUser } = useAppStateContext();
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [commentText, setCommentText] = useState("");

  const submitComment = async (comment: string) => {
    const newComment = {
      content: comment,
      createdAt: Timestamp.fromDate(new Date()),
      id: uuid(),
      userId: loggedInUser?.id || "",
    };
    const answerRef = doc(db, "answers", answerId);
    await updateDoc(answerRef, {
      comments: arrayUnion(newComment),
    });
    updateComment(answerId, newComment);
    setIsAddingComment(false);
    setCommentText("");
  };

  const removeComment = async (comment: Comment) => {
    if (window.confirm("You sure you wanna delete this comment?") == true) {
      const answerRef = doc(db, "answers", answerId);
      await updateDoc(answerRef, {
        comments: arrayRemove(comment),
      });
      updateComment(answerId, comment, true);
    }
  };

  return (
    <>
      {comments.map((comment: Comment) => (
        <div key={comment.id} className="comment-response">
          <Link to={`/${comment.userId}`}>
            {users[comment.userId] && users[comment.userId].name}
          </Link>
          {" // "}
          {comment.content}
          {comment.userId === loggedInUser?.id && (
            <span onClick={() => removeComment(comment)} className="actions">
              X
            </span>
          )}
        </div>
      ))}
      <span className="add-comment">
        {isAddingComment && (
          <span>
            <textarea
              className="comment-box q-a"
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
            ></textarea>
          </span>
        )}
        <span className="editor-actions">
          <div
            className="fake-comment"
            onClick={() => setIsAddingComment(!isAddingComment)}
          >
            {isAddingComment ? "cancel" : "Add Comment"}
          </div>
          {isAddingComment && (
            <div
              className={commentText ? "button" : "button disabled"}
              onClick={() => submitComment(commentText)}
            >
              Submit
            </div>
          )}
        </span>
      </span>
    </>
  );
}

export default Comments;
