import { useAppStateContext } from "../../contexts/AppContext";
import Friends from "../profile/friends";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Link } from "react-router-dom";

const googleAuthProvider = new GoogleAuthProvider();
const auth = getAuth();

function Home() {
  const { loggedInUser } = useAppStateContext();
  const logout = async () => {
    if (window.confirm("Are you sure you want to logout?")) {
      await auth.signOut();
    }
  };

  const signup = async () => {
    try {
      await signInWithPopup(auth, googleAuthProvider);
    } catch (error) {
      alert("Error: check console and DM Arash...");
      console.log(error);
    }
  };

  return (
    <div className="q-box qu-bg--gray_ultralight">
      <div className="home-header-container">
        <img
          className="logo"
          src="https://firebasestorage.googleapis.com/v0/b/mesuc-space.appspot.com/o/wordart%20(2).png?alt=media&token=fc59eef7-68e1-436d-a4a5-608f4c24ba5e"
        />
        <div className="home-actions-container">
          {loggedInUser?.id ? (
            <Link className="button" to={`/${loggedInUser.id}`}>
              Go to Profile
            </Link>
          ) : (
            <div className="button" onClick={signup}>
              Sign-up/Login
            </div>
          )}
          {loggedInUser?.id && (
            <div className="button" onClick={logout}>
              Logout
            </div>
          )}
        </div>
      </div>
      <div className="home-friends-container">
        <Friends />
      </div>
    </div>
  );
}

export default Home;
