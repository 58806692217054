import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { createContext, useContext } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyBY5mKNfQnj4MSLXVuryGD09tFGvdtP5tw",
  authDomain: "mesuc-space.firebaseapp.com",
  projectId: "mesuc-space",
  storageBucket: "mesuc-space.appspot.com",
  messagingSenderId: "494397732057",
  appId: "1:494397732057:web:8519b8a6e32cad656dfac8",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app, "gs://mesuc-space.appspot.com/");

const FirebaseContext = createContext({ db, storage });

function FirebaseProvider({ children }: { children: React.ReactNode }) {
  return (
    <FirebaseContext.Provider value={{ db, storage }}>
      {children}
    </FirebaseContext.Provider>
  );
}

export function useFirebaseContext() {
  return useContext(FirebaseContext);
}

export default FirebaseProvider;
