import { useAppStateContext } from "../../contexts/AppContext";
import { Link } from "react-router-dom";

function Friends({ requiresGrid }: { requiresGrid?: boolean }) {
  const { users } = useAppStateContext();
  return (
    <div
      className={`friend-pic-container ${
        requiresGrid ? "requires-grid" : "flex"
      }`}
    >
      {Object.values(users).map(({ id, photo, name }) => (
        <figure key={id}>
          <figcaption>{name && <Link to={`/${id}`}>{name}</Link>}</figcaption>
          <img src={photo} alt={`${name} user pic`} />
        </figure>
      ))}
    </div>
  );
}

export default Friends;
