import { updateUserPhoto } from "../../actions/index";
import {
  useAppStateContext,
  useAppStateDispatch,
} from "../../contexts/AppContext";
import { useFirebaseContext } from "../../contexts/firebaseContext";
import addFavoritesIcon from "../../images/addFavoritesIcon.gif";
import addFriendIcon from "../../images/addFriendIcon.gif";
import addToGroupIcon from "../../images/addToGroupIcon.gif";
import blockUser from "../../images/blockUser.gif";
import forwardMailIcon from "../../images/forwardMailIcon.gif";
import messagefriend from "../../images/messagefriend.gif";
import rankUserIcon from "../../images/rankUserIcon.gif";
import sendMailIcon from "../../images/sendMailIcon.gif";
import type { User } from "../../types";
import Answers from "./answers";
import Friends from "./friends";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

const setUserDefaults = (user: Partial<User>): User => {
  const [_, queryParams] = (user.youtube || "").split("?");
  const urlParams = new URLSearchParams(queryParams);
  return {
    ...user,
    id: user.id || "",
    lastLogin: new Date().toLocaleDateString(),
    name: user.name || `mesuceuser-${user?.id?.slice(0, 4).toLowerCase()}`,
    youtube: urlParams.get("v") || "",
    photo: user.photo,
  };
};

function Content(props: User & { isLoading: boolean }) {
  const {
    id,
    lastLogin,
    bio,
    general,
    location,
    movie,
    music,
    name,
    photo,
    quote,
    tv,
    youtube,
    css,
  } = setUserDefaults(props);
  const { storage, db } = useFirebaseContext();
  const { loggedInUser } = useAppStateContext();
  const dispatch = useAppStateDispatch();

  const [percent, setPercent] = useState<number>(0);
  const [staggedPhoto, setStagedPhoto] = useState<{
    file: any;
    fileUrl: string;
  }>({ fileUrl: "", file: "" });

  useEffect(() => {
    return () => {
      const customStyleNode = document.getElementById("customStyle");
      if (customStyleNode) {
        customStyleNode.innerHTML = "";
      }
    };
  }, []);

  useEffect(() => {
    let customStyleNode = document.getElementById("customStyle");
    if (!customStyleNode) {
      const head = document.head || document.getElementsByTagName("head")[0];
      const _customStyleNode = document.createElement("style");
      _customStyleNode.setAttribute("id", "customStyle");
      head.appendChild(_customStyleNode);
      customStyleNode = _customStyleNode;
    }

    // always reset
    customStyleNode.innerHTML = "";
    css && customStyleNode.appendChild(document.createTextNode(css));
  }, [css]);

  const handlePhotoClick = () => {
    const fileInput = document.getElementById("img");
    if (fileInput && loggedInUser?.id === id) {
      fileInput.click();
    }
  };

  const resetPhoto = () => {
    const imageElement = document.getElementById("img");
    if (imageElement && "value" in imageElement) {
      imageElement.value = "";
    }
    setStagedPhoto({
      file: "",
      fileUrl: "",
    });
  };

  const stagePhoto = (event: any) => {
    const img = event.target.files[0];
    if (!img) {
      alert("Please upload an image first!");
    }
    setStagedPhoto({
      file: img,
      fileUrl: window.URL.createObjectURL(img),
    });
  };

  const uploadPhoto = () => {
    // visually start the upload
    setPercent(0.01);

    const storageRef = ref(storage, staggedPhoto?.file?.name || uuid());

    const uploadTask = uploadBytesResumable(storageRef, staggedPhoto?.file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setPercent(percent);
      },
      (err) => {
        alert("ERROR, check console and DM arash.");
        console.log(err);
        setPercent(0);
        resetPhoto();
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          await updateUserPhoto(db, {
            userId: loggedInUser?.id || "",
            photoUrl: url,
          });
          setPercent(0);
          resetPhoto();
        });
      }
    );
  };

  const editField = (element: EventTarget, path: keyof User) => {
    if (loggedInUser?.id !== id) {
      return;
    }
    dispatch({
      type: "editProfile",
      path,
      element,
      value: props[path] || "",
    });
  };

  // jenk af but avoids flashing
  if (props.isLoading) {
    return null;
  }

  return (
    <>
      {loggedInUser?.id === id && (
        <div
          onClick={(e) => editField(e.target, "css")}
          className="neon editable"
        >
          edit css
        </div>
      )}
      <aside className="profile-sidebar">
        <header>
          <h1
            id="name"
            className="editable"
            onClick={(e) => editField(e.target, "name")}
          >
            {name}
          </h1>
        </header>
        <div className="profile-picture-container">
          {percent ? (
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : null}
          {photo && !percent ? (
            <img
              className="profile-pic editable"
              src={staggedPhoto.fileUrl || photo}
              onClick={handlePhotoClick}
            />
          ) : null}
          {staggedPhoto.fileUrl && !percent && (
            <div>
              <div className="button" onClick={resetPhoto}>
                Cancel
              </div>
              <div onClick={uploadPhoto} className="button">
                Upload Photo
              </div>
            </div>
          )}
          {loggedInUser?.id === id && (
            <div>
              <input
                placeholder="asdf"
                type="file"
                onChange={stagePhoto}
                accept="/image/*"
                style={{ display: "none" }}
                id="img"
              />
            </div>
          )}
          <div className="personal-msg">
            <b
              id="quote"
              className="editable"
              onClick={(e) => editField(e.target, "quote")}
            >
              {quote}
            </b>
            <ul
              id="location"
              className="gender-age editable"
              onClick={(e) => editField(e.target, "location")}
              dangerouslySetInnerHTML={{
                __html: location || "",
              }}
            ></ul>
          </div>

          <ul className="last-login">
            <li>Last Login:</li>
            <li>{lastLogin}</li>
          </ul>
          {!percent && (
            <p className="pics-videos">
              View My:{" "}
              <a href="#">
                <b>Pics</b>
              </a>{" "}
              |{" "}
              <a href="#">
                <b>Videos</b>
              </a>
            </p>
          )}
        </div>

        <section className="contact-box">
          {name && <h2>Contacting {name}</h2>}

          <figure className="contact-images">
            <img src={sendMailIcon} alt="Send Message" />
            <img src={forwardMailIcon} alt="Forward to Friend" />
            <img src={addFriendIcon} alt="Add to Friends" />
            <img src={addFavoritesIcon} alt="Add to Favorites" />
            <img src={messagefriend} alt="IM" />
            <img src={blockUser} alt="Block" />
            <img src={addToGroupIcon} alt="Add to Group" />
            <img src={rankUserIcon} alt="Rank User" />
          </figure>
        </section>

        <section className="mesucspace-url-box">
          <h3>
            <b>MeSucSpace URL:</b>
          </h3>
          <p>{`http://www.mesucspace.com/${name?.toLowerCase()}`}</p>
        </section>

        <section className="interests">
          <table className="sidebar-table">
            <caption>
              <h2 className="sidebar-table-h2">{name}'s Interests</h2>
            </caption>

            <tbody>
              <tr>
                <th className="sidebar-table-header" scope="row">
                  General
                </th>
                <td className="sidebar-table-data">
                  <p
                    className="editable"
                    onClick={(e) => editField(e.target, "general")}
                    id="general"
                    dangerouslySetInnerHTML={{
                      __html: general || "",
                    }}
                  ></p>
                </td>
              </tr>

              <tr>
                <th className="sidebar-table-header" scope="row">
                  Music
                </th>
                <td
                  className="sidebar-table-data editable"
                  id="music"
                  onClick={(e) => editField(e.target, "music")}
                  dangerouslySetInnerHTML={{
                    __html: music || "",
                  }}
                ></td>
              </tr>

              <tr>
                <th className="sidebar-table-header" scope="row">
                  Movies
                </th>
                <td
                  className="sidebar-table-data editable"
                  id="movie"
                  onClick={(e) => editField(e.target, "movie")}
                  dangerouslySetInnerHTML={{
                    __html: movie || "",
                  }}
                ></td>
              </tr>

              <tr>
                <th className="sidebar-table-header" scope="row">
                  Television
                </th>
                <td
                  onClick={(e) => editField(e.target, "tv")}
                  className="sidebar-table-data editable"
                  id="tv"
                  dangerouslySetInnerHTML={{
                    __html: tv || "",
                  }}
                ></td>
              </tr>
            </tbody>
          </table>
        </section>
      </aside>

      <main>
        <section className="user-network">
          <header>{name && <h2>{name} is in your extended network</h2>}</header>
        </section>

        <section className="blog">
          <p
            className="editable"
            onClick={(e) => editField(e.target, "youtube")}
          >
            <b>Me as a youtube video</b>
          </p>
          {youtube && (
            <iframe
              width="430"
              height="315"
              src={`https://www.youtube.com/embed/${youtube}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          )}
        </section>

        <section className="blurbs">
          <header className="main-section-header">
            <h2 className="main-section-h2">About {name}</h2>
          </header>
          <p
            className="editable"
            onClick={(e) => editField(e.target, "bio")}
            id="bio"
            dangerouslySetInnerHTML={{ __html: bio || "" }}
          ></p>
        </section>

        <section className="comment-wall">
          <header className="main-section-header">
            <h2 className="main-section-h2">{name}'s Q&A</h2>
          </header>
          <Answers />
        </section>

        <section className="friends">
          <header className="main-section-header">
            <h2 className="main-section-h2">{name}'s Friends</h2>
          </header>

          <p>
            <b>
              {name} has <span className="focus-highlight">73090713</span>{" "}
              Friends.
            </b>
          </p>

          <Friends requiresGrid />
          <p className="friends-list-link">
            <a href="#">View All of {name}'s Friends</a>
          </p>
        </section>
      </main>
    </>
  );
}

export default Content;
