import { useAppStateContext } from "../../contexts/AppContext";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Link } from "react-router-dom";

const googleAuthProvider = new GoogleAuthProvider();
const auth = getAuth();

function Header() {
  const { loggedInUser } = useAppStateContext();

  const signup = async () => {
    try {
      await signInWithPopup(auth, googleAuthProvider);
    } catch (error) {
      alert("Error: check console and DM Arash...");
      console.log(error);
    }
  };

  const signupOrLogout = async () => {
    if (
      loggedInUser?.id &&
      window.confirm("Are you sure you want to logout?")
    ) {
      await auth.signOut();
      window.location.replace("/");
      return;
    }

    if (!loggedInUser?.id) {
      await signup();
      window.location.replace("/");
    }
  };

  return (
    <header className="main-header">
      <nav className="search-bar">
        <ul className="topnav home">
          <li>
            <Link to="/">MeSucSpace.com</Link>
          </li>
          <li>
            <Link to="/">Home</Link>
          </li>
        </ul>

        <form>
          <label>
            <input type="text" name="search" />
          </label>

          <input
            className="submit-btn"
            type="submit"
            name="submit-button"
            value="Search"
          />
        </form>

        <ul className="topnav signup">
          <li>
            <a href="#">Help</a>
          </li>
          <li>
            <a href="#" onClick={signupOrLogout}>
              {loggedInUser?.id ? "logout" : "SignUp"}
            </a>
          </li>
        </ul>
      </nav>

      <nav className="navbar">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <a href="#">BRB</a>
          </li>
          <li>
            <a href="#">JIRA</a>
          </li>
          <li>
            <a href="#">Slack Notifications: 999</a>
          </li>
          <li>
            <a href="#">stackoverflow: how to center a div</a>
          </li>
          <li>
            <a href="#">Paper memorial page</a>
          </li>
          <li>
            <a href="#">Build Failures: All</a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
