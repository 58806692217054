function Footer() {
  return (
    <footer className="footer">
      <nav>
        <ul>
          <li>
            <a href="#">About</a>
          </li>
          <li>
            <a href="#">FAQ</a>
          </li>
          <li>
            <a href="#">Terms</a>
          </li>
          <li>
            <a href="#">Privacy</a>
          </li>
          <li>
            <a href="#">Safety Tips</a>
          </li>
          <li>
            <a href="#">Contact MeSucSpace</a>
          </li>
          <li>
            <a href="#">Report Inappropriate Content</a>
          </li>
          <li>
            <a href="#">Promote!</a>
          </li>
          <li>
            <a href="#">Advertise</a>
          </li>
          <li id="last">
            <a href="#">MeSucSpace International</a>
          </li>
        </ul>
      </nav>

      <small>©2022-2023 MeSucSpace.com. All Rights Reserved.</small>
    </footer>
  );
}

export default Footer;
