import {
  useAppStateContext,
  useAppStateDispatch,
} from "../../contexts/AppContext";
import { useFirebaseContext } from "../../contexts/firebaseContext";
import type { User } from "../../types";
import Content from "./content";
import Footer from "./footer";
import Header from "./header";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function Profile() {
  let { profileId } = useParams<string>();
  const { db } = useFirebaseContext();
  const dispatch = useAppStateDispatch();
  const { users, loggedInUser } = useAppStateContext();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (!profileId) {
        // TOOD: route back home
        return;
      }
      const docRef = doc(db, "users", profileId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const docData = (await docSnap.data()) as User;
        dispatch({
          type: "updateUser",
          user: docData,
        });
        dispatch({
          type: "activeProfile",
          activeProfileId: profileId,
        });
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  if (!profileId || isLoading) {
    return <></>;
  }

  return (
    <div className="master-container">
      <Header />
      <div
        className={`page-content-container ${
          loggedInUser?.id === profileId ? "your-profile" : ""
        }`}
      >
        <Content {...users[profileId]} isLoading={isLoading} />
      </div>
      <Footer />
    </div>
  );
}

export default Profile;
