import {
  useAppStateContext,
  useAppStateDispatch,
} from "../../contexts/AppContext";
import { useFirebaseContext } from "../../contexts/firebaseContext";
import type { Answer, Comment } from "../../types";
import Comments from "./comments";
import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
  orderBy,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";

function Answers() {
  const dispatch = useAppStateDispatch();
  const { activeProfileId, questions, loggedInUser, answers } =
    useAppStateContext();
  const { db } = useFirebaseContext();
  const [isLoading, setIsLoading] = useState(true);

  const authorAnswer = (value?: {
    target: EventTarget;
    questionId?: string;
    content?: string;
    answerId?: string;
  }) => {
    if (activeProfileId !== loggedInUser?.id) {
      return;
    }

    // editing
    if (value?.answerId) {
      dispatch({
        type: "editProfile",
        element: value.target,
        value: value.content || "",
        answerId: value?.answerId,
        questionId: value?.questionId,
        path: "answers",
      });
      return;
    }

    const questionId = Object.values(questions)[0].id;
    dispatch({
      type: "answers",
      answers: [
        {
          id: "temp",
          questionId: Object.values(questions)[0].id,
          content: "",
          userId: activeProfileId,
          comments: [],
          createdAt: Timestamp.fromDate(new Date()),
        },
        ...answers,
      ],
    });

    dispatch({
      type: "editProfile",
      element: value?.target,
      value: "",
      questionId: questionId,
      answerId: "temp",
      path: "answers",
    });
  };

  const updateComment = (
    answerId: string,
    comment: Comment,
    remove?: boolean
  ) => {
    const newAnswers = [...answers];
    const answerUpdateIdx = newAnswers.findIndex((i) => i.id === answerId);
    let newComments = [...newAnswers[answerUpdateIdx].comments];

    if (remove) {
      const removedIdx = newComments.findIndex((i) => i.id === comment.id);
      newComments.splice(removedIdx, 1);
    } else {
      newComments = [...newAnswers[answerUpdateIdx].comments, comment];
    }

    newAnswers[answerUpdateIdx] = {
      ...newAnswers[answerUpdateIdx],
      comments: newComments,
    };
    dispatch({
      type: "answers",
      answers: newAnswers,
    });
  };

  useEffect(() => {
    (async () => {
      let _answers: Array<Answer> = [];
      const q = query(
        collection(db, "answers"),
        where("userId", "==", activeProfileId),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        _answers.push(doc.data());
      });
      dispatch({
        type: "answers",
        answers: _answers,
      });
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeAnswer = async (answerId: string) => {
    if (window.confirm("You sure you wanna delete this answer?") == true) {
      const answerRef = doc(db, "answers", answerId);
      await deleteDoc(answerRef);
      let _answers: Array<Answer> = [];
      const q = query(
        collection(db, "answers"),
        where("userId", "==", activeProfileId),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        _answers.push(doc.data());
      });
      dispatch({
        type: "answers",
        answers: _answers,
      });
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <p id="comment-counter">
        <b>
          Displaying <span className="focus-highlight">{answers.length}</span>{" "}
          of <span className="focus-highlight">780610</span> Q&A{" "}
        </b>
        {loggedInUser?.id === activeProfileId && (
          <div
            onClick={(e) =>
              authorAnswer({
                target:
                  "parentElement" in e.target
                    ? (e.target?.parentElement as EventTarget)
                    : e.target,
              })
            }
            className="button"
          >
            Add Answers!
          </div>
        )}
      </p>
      <table>
        <tbody>
          {answers.map(({ content, questionId, id, comments, createdAt }) => (
            <tr key={id}>
              <th scope="row">
                <figure>
                  <figcaption>
                    <div key={questionId}>{questions[questionId]?.content}</div>
                  </figcaption>
                  <img
                    className="comment-img"
                    src={questions[questionId]?.photo}
                    alt="comment-img"
                  />
                </figure>
              </th>
              <td>
                <h3 className="answer-response">
                  {createdAt &&
                    new Date(
                      createdAt.seconds * 1000 + createdAt.nanoseconds / 1000000
                    ).toLocaleString()}
                  {loggedInUser?.id === activeProfileId && (
                    <span onClick={() => removeAnswer(id)} className="actions">
                      X
                    </span>
                  )}
                </h3>
                <div
                  onClick={(e) => {
                    const target = (
                      "parentElement" in e.target
                        ? e.target.parentElement
                        : e.target
                    ) as EventTarget;
                    const parentTarget = (
                      "parentElement" in target
                        ? target.parentElement
                        : e.target
                    ) as EventTarget;
                    authorAnswer({
                      target: parentTarget,
                      questionId,
                      content,
                      answerId: id,
                    });
                  }}
                  className={`answer-content ${
                    loggedInUser?.id === activeProfileId ? "editable" : ""
                  }`}
                  dangerouslySetInnerHTML={{ __html: content }}
                ></div>
                <Comments
                  answerId={id}
                  comments={comments}
                  updateComment={updateComment}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default Answers;
