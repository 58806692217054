import type { Actions, AppState } from "../types";
// @ts-ignore
import cloneDeep from "lodash.clonedeep";
// @ts-ignore
import merge from "lodash.merge";
import type { Dispatch } from "react";
import { createContext, useContext, useReducer } from "react";

const initialAppState: AppState = {
  loggedInUser: null,
  activeProfileId: "",
  users: {},
  questions: {},
  answers: [],
  editing: null,
};

const AppContext = createContext(initialAppState as AppState);
const AppDispatchContext = createContext(null as unknown as Dispatch<Actions>);

function AppStateProvider({ children }: { children: React.ReactNode }) {
  const [appState, dispatch] = useReducer(appStateReducer, initialAppState);
  return (
    <AppContext.Provider value={appState}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
}

export function useAppStateContext() {
  return useContext(AppContext);
}

export function useAppStateDispatch() {
  return useContext(AppDispatchContext);
}

function appStateReducer(appState: AppState, action: Actions): AppState {
  switch (action.type) {
    case "login": {
      return {
        ...appState,
        loggedInUser: {
          id: action.id,
          name: action.name,
        },
      };
    }
    case "logout": {
      return {
        ...appState,
        loggedInUser: null,
      };
    }
    case "updateUser": {
      return {
        ...appState,
        users: {
          ...appState.users,
          [action.user.id]: action.user,
        },
      };
    }
    case "setUsers": {
      const existingUsers = Object.assign({}, appState.users);
      const newUsers = Object.assign({}, action.users);
      return {
        ...appState,
        users: merge(existingUsers, newUsers),
      };
    }
    case "setQuestions": {
      return {
        ...appState,
        questions: action.questions,
      };
    }
    case "activeProfile": {
      return {
        ...appState,
        activeProfileId: action.activeProfileId,
      };
    }
    case "updateAnswer": {
      const updatingIndex = appState.answers.findIndex(
        (i) => i.id === action.id
      );
      const newAnswers = cloneDeep(appState.answers);
      newAnswers[updatingIndex] = {
        ...newAnswers[updatingIndex],
        ...action.value,
      };
      return {
        ...appState,
        answers: newAnswers,
      };
    }
    case "answers": {
      return {
        ...appState,
        answers: action.answers,
      };
    }
    case "editProfile": {
      return {
        ...appState,
        editing: {
          path: action.path,
          element: action?.element,
          value: action.value,
          questionId: action?.questionId,
          answerId: action?.answerId,
        },
      };
    }
    case "resetEdit": {
      let newAnswers;
      const updatingIndex = appState.answers.findIndex((i) => i.id === "temp");
      if (updatingIndex > -1) {
        newAnswers = cloneDeep(appState.answers);
        newAnswers.splice(updatingIndex, 1);
      }
      return {
        ...appState,
        editing: null,
        answers: updatingIndex > -1 ? newAnswers : appState.answers,
      };
    }
    case "updateProfile": {
      const newUsers = { ...appState.users };
      const activeUserId = appState.loggedInUser?.id || "";
      const activeUserProfile = appState.users[activeUserId];

      if (activeUserProfile) {
        newUsers[activeUserId] = Object.assign(
          { ...activeUserProfile },
          { [action.path]: action.value }
        );
      }

      return {
        ...appState,
        users: newUsers,
      };
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}

export default AppStateProvider;
