import { useAppStateContext, useAppStateDispatch } from "./contexts/AppContext";
import { useFirebaseContext } from "./contexts/firebaseContext";
import { Answer } from "./types";
import {
  doc,
  updateDoc,
  setDoc,
  collection,
  Timestamp,
  getDocs,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import Draggable from "react-draggable";

const DEFAULT_CSS = `body {
  background-color: #e5e5e5;
}

.page-content-container {
  background: white;
}

.main-header {
  background-color: rgb(0, 51, 153);
}
`;

const Editable = () => {
  const { db } = useFirebaseContext();
  const { editing, loggedInUser, questions } = useAppStateContext();
  const dispatch = useAppStateDispatch();
  const [state, setState] = useState<{
    isAnswer?: boolean;
    questionId?: string;
    originalValue: string;
    editorValue: string;
    xCord: number;
    yCord: number;
  }>({ originalValue: "", editorValue: "", xCord: 0, yCord: 0 });

  useEffect(() => {
    const handler = (evt: KeyboardEvent) => {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
        isEscape = evt.key === "Escape" || evt.key === "Esc";
      } else {
        // @ts-ignore
        isEscape = evt.keyCode === 27;
      }

      if (isEscape) {
        cancel();
      }
    };

    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.originalValue]);

  useEffect(() => {
    if (!loggedInUser || !editing?.path) {
      return;
    }

    let originalValue = editing.value;
    let editorValue = editing.value;

    const isAnswer = editing.path === "answers";
    const target = editing.element as HTMLElement;
    const box = target.getBoundingClientRect();
    // place above the editable input
    let yCord = box.top + window.pageYOffset - 220;
    let xCord;
    if (yCord > 0) {
      xCord = box.left;
    } else {
      yCord = box.top + window.pageYOffset;
      xCord = box.right;
    }

    if (editing.path === "css" && !editing.value) {
      originalValue = DEFAULT_CSS;
      editorValue = DEFAULT_CSS;
    }

    setState({
      isAnswer,
      questionId: editing.questionId,
      originalValue,
      editorValue,
      xCord,
      yCord,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing?.path]);

  const onChange = (value: string) => {
    setState({
      ...state,
      editorValue: value,
    });

    if (state.isAnswer) {
      dispatch({
        type: "updateAnswer",
        value: {
          content: value,
        },
        id: editing?.answerId,
      });
    } else {
      dispatch({
        type: "updateProfile",
        path: editing?.path || "",
        value,
      });
    }
  };

  const cancel = () => {
    if (state.isAnswer) {
      dispatch({
        type: "updateAnswer",
        value: {
          content: state.originalValue,
        },
        id: editing?.answerId,
      });
    } else {
      dispatch({
        type: "updateProfile",
        path: editing?.path || "",
        value: state.originalValue,
      });
    }
    reset();
  };

  const reset = () => {
    dispatch({
      type: "resetEdit",
    });

    setState({
      editorValue: "",
      originalValue: "",
      xCord: 0,
      yCord: 0,
    });
  };

  const syncAnswers = async () => {
    let answers: Array<Answer> = [];
    const q = query(
      collection(db, "answers"),
      where("userId", "==", loggedInUser?.id),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc: any) => {
      answers.push(doc.data());
    });
    dispatch({
      type: "answers",
      answers,
    });
  };

  const submit = async () => {
    if (state.editorValue.length > 2000) {
      alert("too long: 2000 character max");
      return;
    }

    if (!state.questionId && editing?.path === "answers") {
      alert("select a question to answer, ya dingus!");
      return;
    }

    if (editing?.path === "answers" && state.questionId) {
      // update
      if (editing.answerId && editing.answerId !== "temp") {
        const answerRef = doc(db, "answers", editing.answerId);
        await updateDoc(answerRef, {
          content: state.editorValue,
        });
        await syncAnswers();
        reset();
        return;
      }
      const newAnswerRef = doc(collection(db, "answers"));
      await setDoc(newAnswerRef, {
        content: state.editorValue,
        createdAt: Timestamp.fromDate(new Date()),
        id: newAnswerRef.id,
        userId: loggedInUser?.id,
        questionId: state.questionId,
        comments: [],
      });
      await syncAnswers();
      reset();
      return;
    }

    if (loggedInUser?.id && editing?.path) {
      const userRef = doc(db, "users", loggedInUser?.id);
      await updateDoc(userRef, {
        [editing?.path]: state.editorValue,
      });

      // users-lite editable values
      if (editing?.path === "name") {
        const userLiteRef = doc(db, "users-lite", loggedInUser?.id);
        await updateDoc(userLiteRef, {
          [editing?.path]: state.editorValue,
        });
      }

      reset();
    }
  };

  if (!editing || (state.xCord === 0 && state.yCord === 0)) {
    return <></>;
  }

  return (
    <>
      <span className="background"></span>
      <span style={{ position: "absolute", zIndex: 999999 }}>
        <Draggable
          handle=".handle"
          scale={1}
          position={{ x: state.xCord, y: state.yCord }}
          onDrag={(e, ui) => {
            setState({
              ...state,
              xCord: ui.x,
              yCord: ui.y,
            });
          }}
        >
          <div className="editor-container">
            <span id="drag" className="handle"></span>
            {state.isAnswer && (
              <select
                className="questions"
                value={state.questionId}
                onChange={(event) => {
                  if (event?.target.value === "Please choose a Q to A!") {
                    return;
                  }
                  dispatch({
                    type: "updateAnswer",
                    value: {
                      questionId: event?.target.value,
                    },
                    id: editing?.answerId,
                  });
                  setState({ ...state, questionId: event?.target.value });
                }}
              >
                <option id="0">Please choose a Q to A!</option>
                {Object.values(questions).map((question, index) => (
                  <option value={question.id} key={index}>
                    {question.content}
                  </option>
                ))}
              </select>
            )}
            <textarea
              className="comment-box"
              value={state.editorValue}
              onChange={(e) => onChange(e.target.value)}
            ></textarea>
            <span className="editor-actions">
              <div className="fake-comment" onClick={cancel}>
                cancel
              </div>
              <div
                className={
                  state.editorValue !== state.originalValue
                    ? "button"
                    : "button disabled"
                }
                onClick={submit}
              >
                Submit
              </div>
            </span>
          </div>
        </Draggable>
      </span>
    </>
  );
};

export default Editable;
