import { doc, updateDoc } from "firebase/firestore";
import type { Firestore } from "firebase/firestore";

export const updateUserPhoto = async (db: Firestore, { userId, photoUrl }: { userId: string, photoUrl: string }) => {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
        photo: photoUrl
    });
    const userLiteRef = doc(db, "users-lite", userId);
    await updateDoc(userLiteRef, {
        photo: photoUrl
    });
}